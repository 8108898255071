import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateMachineCapacityChangeRequestDto, MachineCapacityChangeRequestDto, ResultDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class MachineCapacityChangeRequestService {
  apiName = 'Default';
  

  create = (requestDto: CreateMachineCapacityChangeRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ResultDto>({
      method: 'POST',
      url: '/api/app/machine-capacity-change-request',
      body: requestDto,
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  getByVendorId = (input: PagedResultRequestDto, vendorId: number, partialName?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MachineCapacityChangeRequestDto>>({
      method: 'GET',
      url: `/api/app/machine-capacity-change-request/by-vendor-id/${vendorId}`,
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, partialName },
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  getPendingByVendorId = (vendorId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MachineCapacityChangeRequestDto[]>({
      method: 'GET',
      url: `/api/app/machine-capacity-change-request/pending-by-vendor-id/${vendorId}`,
    },
    { apiName: this.apiName, skipHandleError: true,...config });

  constructor(private restService: RestService) {}
}
