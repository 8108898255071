import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateProgrammingDto, ProgrammingDto, YearMonthDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class ProgrammingService {
  apiName = 'Default';
  

  create = (programmingDto: CreateUpdateProgrammingDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProgrammingDto>({
      method: 'POST',
      url: '/api/app/programming',
      body: programmingDto,
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  getAlreadyExists = (machineId: number, year: number, month: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'GET',
      url: `/api/app/programming/already-exists/${machineId}`,
      params: { year, month },
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  getByVendorTeamYearAndMonth = (pagination: PagedResultRequestDto, vendorId: number, teamId?: number, year?: number, month?: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ProgrammingDto>>({
      method: 'GET',
      url: '/api/app/programming/by-vendor-team-year-and-month',
      params: { skipCount: pagination.skipCount, maxResultCount: pagination.maxResultCount, vendorId, teamId, year, month },
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  getExistingYearMonths = (vendorId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, YearMonthDto[]>({
      method: 'GET',
      url: `/api/app/programming/existing-year-months/${vendorId}`,
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  reSend = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProgrammingDto>({
      method: 'POST',
      url: `/api/app/programming/${id}/re-send`,
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  update = (id: string, programmingDto: CreateUpdateProgrammingDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProgrammingDto>({
      method: 'PUT',
      url: `/api/app/programming/${id}`,
      body: programmingDto,
    },
    { apiName: this.apiName, skipHandleError: true,...config });

  constructor(private restService: RestService) {}
}
