import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ResourceTypeDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class ResourceTypeService {
  apiName = 'Default';
  

  getMachineTypes = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ResourceTypeDto[]>({
      method: 'GET',
      url: '/api/app/resource-type/machine-types',
    },
    { apiName: this.apiName, skipHandleError: true,...config });

  constructor(private restService: RestService) {}
}
