import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { SharedModule } from "src/app/shared/shared.module";
import { MobileMenuComponent } from "./mobile-menu";

@NgModule({
  declarations: [MobileMenuComponent],
  imports: [BrowserModule, SharedModule],
  providers: [],
  exports: [MobileMenuComponent]
})
export class MobileModule {}
