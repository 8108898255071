import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { HistoryCapacityChangeRequestDto, HistoryMachineRegistrationRequestDto, HistoryMachineTeamChangeDto, HistoryOperatorTeamChangeDto } from '../dtos/history/models';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  apiName = 'Default';
  

  getCapacityChangeRequestRecordsByVendor = (pagination: PagedResultRequestDto, vendorId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<HistoryCapacityChangeRequestDto>>({
      method: 'GET',
      url: `/api/app/history/capacity-change-request-records-by-vendor/${vendorId}`,
      params: { skipCount: pagination.skipCount, maxResultCount: pagination.maxResultCount },
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  getMachineRegistrationRequestRecordsByVendor = (pagination: PagedResultRequestDto, vendorId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<HistoryMachineRegistrationRequestDto>>({
      method: 'GET',
      url: `/api/app/history/machine-registration-request-records-by-vendor/${vendorId}`,
      params: { skipCount: pagination.skipCount, maxResultCount: pagination.maxResultCount },
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  getMachineTeamChangeRecordsByVendor = (pagination: PagedResultRequestDto, vendorId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<HistoryMachineTeamChangeDto>>({
      method: 'GET',
      url: `/api/app/history/machine-team-change-records-by-vendor/${vendorId}`,
      params: { skipCount: pagination.skipCount, maxResultCount: pagination.maxResultCount },
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  getOperatorTeamChangeRecordsByVendor = (pagination: PagedResultRequestDto, vendorId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<HistoryOperatorTeamChangeDto>>({
      method: 'GET',
      url: `/api/app/history/operator-team-change-records-by-vendor/${vendorId}`,
      params: { skipCount: pagination.skipCount, maxResultCount: pagination.maxResultCount },
    },
    { apiName: this.apiName, skipHandleError: true,...config });

  constructor(private restService: RestService) {}
}
