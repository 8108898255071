import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
import { Constants } from '../consts/constants';

@Injectable({
    providedIn: 'root'
})
export class MonitoringService {
    private appInsights: ApplicationInsights;

    constructor() {
        this.startMonitoring();
    }

    private startMonitoring(): void {
        const instrumentationKey = environment[Constants.ApplicationInsightsConfigurationName].instrumentationKey;

        this.appInsights = new ApplicationInsights({
            config: {
            instrumentationKey: instrumentationKey,
            enableAutoRouteTracking: true
        }});

        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();
    }
        
    logEvent(name: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackEvent({ name: name }, properties);
    }
    
    logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
    
    logException(exception: Error, severityLevel?: number): void {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
        console.error(exception);
    }
    
    logTrace(message: string, properties?: { [key: string]: any }): void {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}