import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { UpdateTeamResourceDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class TeamResourceService {
  apiName = 'Default';
  

  updateTeamInTeamResource = (vendorId: number, updateDto: UpdateTeamResourceDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'PUT',
      url: `/api/app/team-resource/team-in-team-resource/${vendorId}`,
      body: updateDto,
    },
    { apiName: this.apiName, skipHandleError: true,...config });

  constructor(private restService: RestService) {}
}
