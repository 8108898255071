import { CoreModule } from "@abp/ng.core";
import { GdprConfigModule } from "@volo/abp.ng.gdpr/config";
import { SettingManagementConfigModule } from "@abp/ng.setting-management/config";
import {
  InternetConnectionStatusComponent,
  ThemeSharedModule
} from "@abp/ng.theme.shared";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommercialUiConfigModule } from "@volo/abp.commercial.ng.ui/config";
import { AccountAdminConfigModule } from "@volo/abp.ng.account/admin/config";
import { AccountPublicConfigModule } from "@volo/abp.ng.account/public/config";
import { AuditLoggingConfigModule } from "@volo/abp.ng.audit-logging/config";
import { IdentityConfigModule } from "@volo/abp.ng.identity/config";
import { LanguageManagementConfigModule } from "@volo/abp.ng.language-management/config";
import { registerLocale } from "@volo/abp.ng.language-management/locale";
import { SaasConfigModule } from "@volo/abp.ng.saas/config";
import { TextTemplateManagementConfigModule } from "@volo/abp.ng.text-template-management/config";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { APP_ROUTE_PROVIDER } from "./route.provider";
import { OpeniddictproConfigModule } from "@volo/abp.ng.openiddictpro/config";
import { FeatureManagementModule } from "@abp/ng.feature-management";
import { AbpOAuthModule } from "@abp/ng.oauth";
import { ThemeLeptonXModule } from "@volosoft/abp.ng.theme.lepton-x";
import { SideMenuLayoutModule } from "@volosoft/abp.ng.theme.lepton-x/layouts";
import { ServiceWorkerModule } from "@angular/service-worker";
import { LpxSideMenuLayoutModule } from "@volosoft/ngx-lepton-x/layouts";
import { LpxResponsiveModule } from "@volo/ngx-lepton-x.core";
import { SharedModule } from "./shared/shared.module";
import { CommonModule } from "@angular/common";
import { MobileModule } from "./components/menu/mobile.module";
import { ErrorHandlerService } from './shared/services/error-handler-service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MobileModule,

    SharedModule,
    CommonModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale()
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot(),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),

    CommercialUiConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    GdprConfigModule.forRoot({
      privacyPolicyUrl: "gdpr-cookie-consent/privacy",
      cookiePolicyUrl: "gdpr-cookie-consent/cookie"
    }),
    InternetConnectionStatusComponent,
    ThemeLeptonXModule.forRoot({
      defaultTheme: "light"
    }),
    SideMenuLayoutModule.forRoot(),
    LpxSideMenuLayoutModule,
    LpxResponsiveModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000"
    })
  ],
  providers: [APP_ROUTE_PROVIDER, {provide: ErrorHandler, useClass: ErrorHandlerService}],
  bootstrap: [AppComponent]
})
export class AppModule {}
