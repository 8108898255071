import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from './monitoring.service';
import { Constants } from '../consts/constants';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
    private isEnabledAppInsights: boolean = false;

    constructor(private _monitoringService: MonitoringService) {
        this.isEnabledAppInsights = Boolean(environment[Constants.ApplicationInsightsConfigurationName].isEnabled);
     }

    handleError(error: Error): void {
        if (this.isEnabledAppInsights) {
            this._monitoringService.logException(error);
        } else {
            console.error(error);
        }
    }
}