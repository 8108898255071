import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { VendorDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  apiName = 'Default';
  

  getById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, VendorDto>({
      method: 'GET',
      url: `/api/app/vendor/${id}/by-id`,
    },
    { apiName: this.apiName,skipHandleError: true, ...config });

  constructor(private restService: RestService) {}
}
