import { Component } from "@angular/core";

@Component({
  selector: "app-mobile-menu",
  templateUrl: "./mobile-menu.html",
  styleUrls: ["./mobile-menu.scss"]
})
export class MobileMenuComponent {
  isMenuOpen = false;

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
