<nav class="menu-mobile-container" id="#mobile-menu">
  <div
    class="logo-mobile"
    alt="UPM white logo"
    aria-label="UPM white logo"
    href="/"
  ></div>

  <a class="burger" (click)="toggleMenu()" [ngClass]="{'is-open': isMenuOpen}">
    <ng-container *ngIf="isMenuOpen; else closedMenu">
      <!-- Open menu SVG -->
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6.5L6 18.5M6 6.5L18 18.5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ng-container>
    <ng-template #closedMenu>
      <!-- Closed menu SVG -->
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 12.5H17M3 6.5H21M3 18.5H21"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ng-template>
  </a>

  <ul class="menu" [ngClass]="{'is-open': isMenuOpen}">
    <li>
      <a href="/" class="menu-item"><i class="ico-home"></i>{{ "::Menu:Home" | abpLocalization }}</a>
      <a href="/resources" class="menu-item"><i class="ico-operator"></i>{{ "::Menu:Resources" | abpLocalization }}</a>
      <a href="/requests" class="menu-item"><i class="ico-requests"></i>{{ "::Menu:Requests" | abpLocalization }}</a>
      <a href="/demos" class="menu-item"><i class="ico-fronts"></i>Demo</a>
    </li>
  </ul>
</nav>
