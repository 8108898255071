import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/',
        name: '::Menu:Home',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.application,
        requiredPolicy: "HarvestActivityReportWeb.Home"
      },
      {
        path: '/dashboard',
        name: '::Menu:Dashboard',
        iconClass: 'fas fa-chart-line',
        order: 2,
        layout: eLayoutType.application,
        requiredPolicy: 'HarvestActivityReportWeb.Dashboard.Host || HarvestActivityReportWeb.Dashboard.Tenant',
      },
      {
        path: '/resources',
        name: '::Menu:Resources',
        iconClass: 'fas fa-atom',
        order: 3,
        layout: eLayoutType.application,
        requiredPolicy: 'HarvestActivityReportWeb.Resources'
      },
      {
        path: '/requests',
        name: '::Menu:Requests',
        iconClass: 'fas fa-atlas',
        order: 4,
        layout: eLayoutType.application,
        requiredPolicy: 'HarvestActivityReportWeb.Requests'
      },
      {
        path: '/programmings',
        name: '::Menu:Programmings',
        iconClass: 'fas fa-bahai',
        order: 5,
        layout: eLayoutType.application,
        requiredPolicy: 'HarvestActivityReportWeb.Programmings'
      },
    ]);
  };
}
