import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateResourceRegistrationRequestDto, ResourceRegistrationRequestDto, ResultDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class ResourceRegistrationRequestService {
  apiName = 'Default';
  

  create = (requestDto: CreateResourceRegistrationRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ResultDto>({
      method: 'POST',
      url: '/api/app/resource-registration-request',
      body: requestDto,
    },
    { apiName: this.apiName, skipHandleError: true,...config });
  

  getByVendorId = (input: PagedResultRequestDto, vendorId: number, statusCode?: string, requestorId?: number, searchString?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ResourceRegistrationRequestDto>>({
      method: 'GET',
      url: '/api/app/resource-registration-request/by-vendor-id',
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, vendorId, statusCode, requestorId, searchString },
    },
    { apiName: this.apiName, skipHandleError: true,...config });

  constructor(private restService: RestService) {}
}
