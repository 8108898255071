import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { TeamDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  apiName = 'Default';
  

  getByVendorId = (vendorId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TeamDto[]>({
      method: 'GET',
      url: `/api/app/team/by-vendor-id/${vendorId}`,
    },
    { apiName: this.apiName, skipHandleError: true,...config });

  constructor(private restService: RestService) {}
}
