import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { PeriodDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class PeriodService {
  apiName = 'Default';
  

  getFollowingMonths = (date: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PeriodDto[]>({
      method: 'GET',
      url: '/api/app/period/following-months',
      params: { date },
    },
    { apiName: this.apiName, skipHandleError: true,...config });

  constructor(private restService: RestService) {}
}
