import { PagedResultRequestDto } from "@abp/ng.core";
import { UserDto } from "@proxy/dtos/models";

export class Constants{
    static readonly AmountOfResultsPerPage = 12;
    static readonly OneHundred = 100;
    static readonly OneThousand = 100;
    static readonly NotificationAutoCloseTime = 5000;
    static readonly ForwarderResourceTypeCode = 'FORWARD';
    static readonly HarvesterResourceTypeCode = 'HARVEST';
    static readonly ShiftRestHours = 1;
    static readonly MinimumCapacityAmount = 1;
    static readonly MaximumCapacityAmount = 30;
    static readonly ReasonPending = 'PENDING';
    static readonly ReasonApproved = 'APPROVED';
    static readonly ReasonRejected = 'REJECTED';
    static readonly SystemError = 'AERRS';
    static readonly PaginatedRequestDto = { skipCount: 0, maxResultCount: this.AmountOfResultsPerPage } as PagedResultRequestDto;
    static readonly NonPaginatedRequestDto = { skipCount: 0, maxResultCount: this.OneThousand } as PagedResultRequestDto;
    static readonly PeriodStartDaysRange = 5;
    static readonly MaxDayOfMonthToEditSecondFortnight = 10;
    static readonly DefaultContractedHours = 585;
    static readonly HomeHistoricRecordsAmount = 10;
    static readonly MinimumAmountOfPeriodsToWork = 3;
    static readonly LocalAdminEmail = "admin@abp.io";
    static readonly ApplicationInsightsConfigurationName = 'applicationInsights';
    static readonly LocalAdminUserDto = { id: 0, userName: "admin", name: "AdministradorLocal", vendorId: 57, email: "testUser@testEmail.com"} as UserDto;
}