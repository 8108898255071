import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { UserDto } from '../dtos/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiName = 'Default';

  private static authenticatedUser: UserDto;

  getByEmailByEmail = (email: string, config?: Partial<Rest.Config>) => {

    if (UserService.authenticatedUser){
      return new Observable<UserDto>((observer) => {
        observer.next(UserService.authenticatedUser);
        observer.complete();
      });
    }

    return this.restService.request<any, UserDto>({
      method: 'GET',
      url: '/api/app/user/by-email',
      params: { email },
    },
    { apiName: this.apiName, skipHandleError: true,...config }).pipe(
      map((user: UserDto) => {
        UserService.authenticatedUser = user;
        return user;
      })
    );
  }

  constructor(private restService: RestService) {}
}
