import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ShiftDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class ShiftService {
  apiName = 'Default';
  

  getAllByVendorByVendorId = (vendorId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ShiftDto[]>({
      method: 'GET',
      url: `/api/app/shift/by-vendor/${vendorId}`,
    },
    { apiName: this.apiName, skipHandleError: true,...config });

  constructor(private restService: RestService) {}
}
