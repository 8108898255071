import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { MachineResourceDto } from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class MachineResourceService {
  apiName = 'Default';
  

  getByVendorId = (input: PagedResultRequestDto, vendorId: number, searchString?: string, resourceTypeCode?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MachineResourceDto>>({
      method: 'GET',
      url: `/api/app/machine-resource/by-vendor-id/${vendorId}`,
      params: { skipCount: input.skipCount, maxResultCount: input.maxResultCount, searchString, resourceTypeCode },
    },
    { apiName: this.apiName, skipHandleError: true,...config });

  constructor(private restService: RestService) {}
}
