import { Component } from "@angular/core";
import { ConfigStateService } from "@abp/ng.core";
import { UserMenuService } from '@abp/ng.theme.shared';
import { UserService } from "@proxy/services";
import { eUserMenuItems } from "@volosoft/abp.ng.theme.lepton-x";

@Component({
  selector: "app-root",
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
    <abp-gdpr-cookie-consent></abp-gdpr-cookie-consent>
    <abp-internet-status></abp-internet-status>
  `
})
export class AppComponent {
  constructor(
    private configState: ConfigStateService,
    private userService: UserService,
    private userMenu: UserMenuService
  ) {

    this.userMenu.removeItem(eUserMenuItems.LinkedAccounts);
    this.userMenu.removeItem(eUserMenuItems.AuthorityDelegation);
    this.userMenu.removeItem(eUserMenuItems.MyAccount);
    this.userMenu.removeItem(eUserMenuItems.SecurityLogs);

    const userState = this.configState.getOne("currentUser");

    if (userState && userState.isAuthenticated && userState.email && userState.email != 'admin@abp.io'){
      this.userService.getByEmailByEmail(userState.email).subscribe();
    }
  }
}
